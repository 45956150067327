import React, { useState, useEffect } from 'react';
import { getFoods, addFood, updateFood } from './api';

function FoodList() {
  const [foods, setFoods] = useState([]);
  const [token, setToken] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editFoodId, setEditFoodId] = useState(null);
  const [editedFoods, setEditedFoods] = useState({});
  const [newFood, setNewFood] = useState({
    name: '',
    weight :0,
    calories: 0,
    protein: 0,
    carbohydrates: 0,
    fat: 0
  });

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to login page if token is not valid
      window.location.href = '/login';
    } else {
      fetchFoods();
    }
  }, []);

  async function fetchFoods() {
    try {
      const fetchedFoods = await getFoods(localStorage.getItem('authToken'));
      setFoods(fetchedFoods.foods);
    } catch (error) {
      console.error('Error fetching foods:', error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFood(prevNewFood => ({
      ...prevNewFood,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addFood(newFood, localStorage.getItem('authToken'));
      setNewFood({
        name: '',
        weight :0,
        calories: 0,
        protein: 0,
        carbohydrates: 0,
        fat: 0
      });
      setShowForm(false);
      // Fetch foods again to update the list
      const fetchedFoods = await getFoods(localStorage.getItem('authToken'));
      setFoods(fetchedFoods.foods);
    } catch (error) {
      console.error('Error adding food:', error);
    }
  };

  const handleUpdateFood = async (foodId) => {
    try {
      console.log(editedFoods[foodId])
      await updateFood(editedFoods[foodId], token);
      // Update the local state with the edited food
      const updatedFoods = foods.map(food =>
        food.id === foodId ? { ...food, ...editedFoods[foodId] } : food
      );
      setFoods(updatedFoods);
      setEditFoodId(null);
      setEditedFoods({});
    } catch (error) {
      console.error('Error updating food:', error);
    }
  };

  const handleEditInputChange = (foodId, e) => {
    const { name, value } = e.target;

  // Parse numeric fields to integers, or keep the value as-is for other fields
    const valueInt = parseInt(value,10);


    setEditedFoods(prevEditedFoods => ({
      ...prevEditedFoods,
      [foodId]: {
        ...foods.find(food => food.id === foodId),
        [name]: valueInt
      }
    }));
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h2 className="mb-4">Food List</h2>
          <button
            className="btn btn-primary mb-3"
            onClick={() => setShowForm(!showForm)}
          >
            Add New Food
          </button>
          {showForm && (
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name *</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newFood.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="weight" className="form-label">Weight</label>
              <input
                type="number"
                className="form-control"
                id="weight"
                name="weight"
                value={newFood.weight}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="calories" className="form-label">Calories</label>
              <input
                type="number"
                className="form-control"
                id="calories"
                name="calories"
                value={newFood.calories}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="protein" className="form-label">Protein</label>
              <input
                type="number"
                className="form-control"
                id="protein"
                name="protein"
                value={newFood.protein}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="carbohydrates" className="form-label">Carbohydrates</label>
              <input
                type="number"
                className="form-control"
                id="carbohydrates"
                name="carbohydrates"
                value={newFood.carbohydrates}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fat" className="form-label">Fat</label>
              <input
                type="number"
                className="form-control"
                id="fat"
                name="fat"
                value={newFood.fat}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit" className="btn btn-success mb-3">Add Food</button>
          </form>
          )}
          <ul className="list-group">
          {foods.map(food => (
            <li key={food.id} className="list-group-item">
              {editFoodId === food.id ? (
                <div>
                  <button
                    className="btn btn-primary btn-sm mr-2"
                    onClick={() => setEditFoodId(null)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => handleUpdateFood(food.id)}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div>
                  <strong>{food.name}</strong>
                  <button
                    className="btn btn-link btn-sm ml-2"
                    onClick={() => setEditFoodId(food.id)}
                  >
                    Edit
                  </button>
                </div>
              )}
              {editFoodId === food.id && (
                <div>
                  <label htmlFor={`name-${food.id}`} className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id={`name-${food.id}`}
                    value={editedFoods[food.id]?.name || food.name}
                    onChange={(e) => handleEditInputChange(food.id, e)}
                  />
                  <label htmlFor={`weight-${food.id}`} className="form-label">
                    Weight
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="weight"
                    id={`weight-${food.id}`}
                    value={editedFoods[food.id]?.weight || food.weight}
                    onChange={(e) => handleEditInputChange(food.id, e)}
                  />
                  <label htmlFor={`calories-${food.id}`} className="form-label">
                    Calories
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="calories"
                    id={`calories-${food.id}`}
                    value={editedFoods[food.id]?.calories || food.calories}
                    onChange={(e) => handleEditInputChange(food.id, e)}
                  />
                  <label htmlFor={`protein-${food.id}`} className="form-label">
                    Protein
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="protein"
                    id={`protein-${food.id}`}
                    value={editedFoods[food.id]?.protein || food.protein}
                    onChange={(e) => handleEditInputChange(food.id, e)}
                  />
                  <label htmlFor={`carbohydrates-${food.id}`} className="form-label">
                    Carbohydrates
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="carbohydrates"
                    id={`carbohydrates-${food.id}`}
                    value={editedFoods[food.id]?.carbohydrates || food.carbohydrates}
                    onChange={(e) => handleEditInputChange(food.id, e)}
                  />
                  <label htmlFor={`fat-${food.id}`} className="form-label">
                    Fat
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="fat"
                    id={`fat-${food.id}`}
                    value={editedFoods[food.id]?.fat || food.fat}
                    onChange={(e) => handleEditInputChange(food.id, e)}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
        </div>
      </div>
    </div>
  );
}

export default FoodList;