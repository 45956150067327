import React, { useState, useEffect } from 'react';
import { getFoods, addConsumedFood } from './api';

function Summary() {
  const [foods, setFoods] = useState([]);
  const [consumedFoods, setConsumedFoods] = useState([]);
  const [selectedFoodId, setSelectedFoodId] = useState('');

  const fetchData = async () => {
    try {
      const fetchedFoods = await getFoods(localStorage.getItem('authToken'));
      setFoods(fetchedFoods.foods);
      setConsumedFoods(fetchedFoods.consumedFoods);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to login page if token is not valid
      window.location.href = '/login';
    } else {
      fetchData();
    }
  }, []);

  const calculateTotalCalories = (foods, consumedFoods) => {
    return consumedFoods.reduce((totalCalories, consumedFood) => {
      const food = foods.find(food => food.id === consumedFood.foodId);
      return totalCalories + (food ? food.calories : 0);
    }, 0);
  };

  const today = new Date().toLocaleDateString();
  const yesterday = new Date(new Date() - 24 * 60 * 60 * 1000).toLocaleDateString();

  const daysData = consumedFoods.reduce((days, consumedFood) => {
    const date = new Date(consumedFood.timestamp).toLocaleDateString();
    if (!days[date]) {
      days[date] = [];
    }
    days[date].push(consumedFood);
    return days;
  }, {});

  const handleAddFoodEaten = async (foodId) => {
    const authToken = localStorage.getItem('authToken');
    try {
      await addConsumedFood(foodId, authToken);
      fetchData(); // Fetch data again to update the consumed foods
    } catch (error) {
      console.error('Error adding consumed food:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h2 className="mb-4">Summary</h2>
          {Object.keys(daysData).map((date, index) => {
            const foodsConsumed = daysData[date];
            const totalCalories = calculateTotalCalories(foods, foodsConsumed);
            const displayDate = date === today ? 'Today' : date === yesterday ? 'Yesterday' : date;

            return (
              <div className="card mb-4" key={index}>
                <div className="card-header">
                  {displayDate}
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    Total Calories: {totalCalories}
                  </h5>
                  <details>
                    <summary>View details</summary>
                    <ul className="list-group">
                      {foodsConsumed.map(consumedFood => (
                        <li key={consumedFood.timestamp} className="list-group-item">
                          Food: {foods.find(food => food.id === consumedFood.foodId)?.name || ''}
                          <br />
                          Timestamp: {new Date(consumedFood.timestamp).toLocaleTimeString()}
                        </li>
                      ))}
                    </ul>
                  </details>
                  {date === today && (
                    <div>
                      <select
                        className="form-select"
                        value={selectedFoodId}
                        onChange={e => setSelectedFoodId(e.target.value)}
                      >
                        <option value="">Select Food</option>
                        {foods.map(food => (
                          <option key={food.id} value={food.id}>
                            {food.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => handleAddFoodEaten(selectedFoodId)}
                      >
                        Add Food Eaten
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Summary;