import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import FoodList from './FoodList';
import Summary from './Summary';
import Login from './Login';
import Register from './Register';

function App() {
  return (
    <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Summary Page
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/foods" className="nav-link">
                Food List
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container mt-5">
        <Switch>
          <Route path="/foods" component={FoodList} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/" component={Summary} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;