import axios from 'axios';

const API_BASE_URL = '/api'; // Relative URL for the API
// Replace '/api' with the appropriate API endpoint on your server

const api = axios.create({
    baseURL: API_BASE_URL,
});


export const registerUser = async(userData) => {
    try {
        const response = await api.post(`${API_BASE_URL}/users/register`, userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const loginUser = async(userData) => {
    try {
        const response = await api.post(`${API_BASE_URL}/users/login`, userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getFoods = async(token) => {
    try {
        const response = await api.get(`${API_BASE_URL}/foods`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addFood = async(foodData, token) => {
    try {
        const response = await api.post(`${API_BASE_URL}/foods`, foodData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateFood = async(food, token) => {
    try {
        const response = await api.put(`${API_BASE_URL}/foods/${food.id}`, food, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addConsumedFood = async(foodId, token) => {
    try {
        const response = await api.post(`${API_BASE_URL}/foods/consumed-foods`, { foodId }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const isAuthenticated = () => {
    // Check if the user has a valid token
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
    return !!token; // Return true if token exists, false if not
};