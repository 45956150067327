import React, { useState } from 'react';
import { loginUser } from './api';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');

  const handleLogin = async () => {
    try {
      const userData = { username, password };
      const response = await loginUser(userData);
      const authToken = response.token;
  
      // Store the token in localStorage or sessionStorage
      if(authToken){
        localStorage.setItem('authToken',authToken);
        window.location.href = '/summary';
      }

    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <div>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button onClick={handleLogin}>Login</button>

      <button onClick={()=>window.location.href = '/register'}>Register</button>
    </div>
  );
}

export default Login;